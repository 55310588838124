var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height login_desktop pb-5",staticStyle:{"align-items":"center"},attrs:{"fluid":""}},[_c('v-row',{class:_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly ? 'px-16' : '',attrs:{"align":"center","justify":"center"}},[_c('v-container',{class:_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
          ? 'mx-16'
          : '',attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"px-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-img',{staticStyle:{"vertical-align":"-webkit-baseline-middle !important"},attrs:{"src":require('@/assets/img/icon_fujifilm.png'),"max-height":_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                  ? '150px'
                  : '100px',"max-width":_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                  ? '300px'
                  : '200px',"contain":""}})],1),(_vm.status_login === true)?_c('v-col',{staticClass:"mt-10 px-0",attrs:{"cols":"12","align":"center"}},[_c('div',{staticClass:"black--text py-2",staticStyle:{"font-size":"16px","font-style":"normal","font-weight":"450","line-height":"25px","letter-spacing":"0em"}},[_vm._v(" กรุณาระบุรหัสผ่านที่ได้รับในอีเมล ")]),_c('div',{staticClass:"black--text pt-0 pb-2",staticStyle:{"font-size":"16px","font-style":"normal","font-weight":"450","line-height":"25px","letter-spacing":"0em"}},[_vm._v(" Please enter the password received in the email. ")])]):_vm._e(),(_vm.status_login === false)?_c('v-col',{staticClass:"mt-10",attrs:{"cols":"12","align":"center"}},[_c('div',{staticClass:"red--text py-2",staticStyle:{"font-size":"16px","font-style":"normal","font-weight":"450","line-height":"25px","letter-spacing":"0em"}},[_vm._v(" รหัสผ่านที่ระบุไม่ถูกต้อง กรุณาระบุรหัสผ่านที่ได้รับในอีเมล ")]),_c('div',{staticClass:"red--text pt-0 pb-2",staticStyle:{"font-size":"16px","font-style":"normal","font-weight":"450","line-height":"25px","letter-spacing":"0em"}},[_vm._v(" Your password is incorrect. Please enter the password received in the email. ")])]):_vm._e(),_c('v-col',{attrs:{"cols":"12\n            ","align":"center"}},[_c('v-row',[(
                  _vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                )?_c('v-col',{attrs:{"cols":"3"}}):_vm._e(),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                    ? 2
                    : 12,"align":_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                    ? 'end'
                    : 'start'}},[_c('p',{class:_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                      ? 'black--text mt-2'
                      : 'black--text my-0',staticStyle:{"font-size":"16px","font-style":"normal","font-weight":"450","line-height":"25px","letter-spacing":"0em"}},[_vm._v(" Transaction ID : ")])]),_c('v-col',{class:_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                    ? ''
                    : 'pt-0',attrs:{"cols":_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                    ? 3
                    : 12}},[_c('v-text-field',{staticStyle:{"border-radius":"10px"},attrs:{"outlined":"","rules":_vm.empty,"color":"green","maxlength":255,"dense":"","placeholder":"xxxxxx (value from RP4)","required":""},model:{value:(_vm.ticket_id),callback:function ($$v) {_vm.ticket_id=$$v},expression:"ticket_id"}})],1)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","align":"center"}},[_c('v-row',[(
                  _vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                )?_c('v-col',{attrs:{"cols":"3"}}):_vm._e(),_c('v-col',{class:_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                    ? ''
                    : 'pt-0',attrs:{"cols":_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                    ? 2
                    : 12,"align":_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                    ? 'end'
                    : 'start'}},[_c('p',{class:_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                      ? 'black--text mt-2'
                      : 'black--text my-0',staticStyle:{"font-size":"16px","font-style":"normal","font-weight":"450","line-height":"25px","letter-spacing":"0em"}},[_vm._v(" Password ID : ")])]),_c('v-col',{class:_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                    ? ''
                    : 'pt-0',attrs:{"cols":_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                    ? 3
                    : 12}},[_c('v-text-field',{staticStyle:{"border-radius":"10px"},attrs:{"outlined":"","rules":_vm.empty,"color":"green","maxlength":255,"dense":"","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1)],1),_c('v-col',{staticClass:"my-4",attrs:{"cols":"12","align":"center"}},[_c('v-row',[(
                  _vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                )?_c('v-col',{attrs:{"cols":"5"}}):_vm._e(),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.lgOnly || _vm.$vuetify.breakpoint.xlOnly
                    ? 3
                    : 12}},[_c('v-btn',{staticClass:"white--text text-capitalize",staticStyle:{"border-radius":"10px","font-size":"16px","font-style":"normal","font-weight":"450","line-height":"25px","letter-spacing":"0em","text-align":"left","height":"45px"},attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.goToHomepage()}}},[_vm._v(" Login ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }