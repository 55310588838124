<template>
  <v-container
    fluid
    class="fill-height login_desktop pb-5"
    style="align-items: center"
  >
    <v-row
      :class="
        $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly ? 'px-16' : ''
      "
      align="center"
      justify="center"
    >
      <v-container
        fluid
        :class="
          $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
            ? 'mx-16'
            : ''
        "
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="px-0" align="center" justify="center">
            <v-col cols="12" align="center">
              <v-img
                :src="require('@/assets/img/icon_fujifilm.png')"
                style="vertical-align: -webkit-baseline-middle !important"
                :max-height="
                  $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                    ? '150px'
                    : '100px'
                "
                :max-width="
                  $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                    ? '300px'
                    : '200px'
                "
                contain
              ></v-img>
            </v-col>

            <v-col
              class="mt-10 px-0"
              cols="12"
              align="center"
              v-if="status_login === true"
            >
              <div
                class="black--text py-2"
                style="
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 450;
                  line-height: 25px;
                  letter-spacing: 0em;
                "
              >
                กรุณาระบุรหัสผ่านที่ได้รับในอีเมล
              </div>
              <div
                class="black--text pt-0 pb-2"
                style="
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 450;
                  line-height: 25px;
                  letter-spacing: 0em;
                "
              >
                Please enter the password received in the email.
              </div>
            </v-col>

            <v-col
              class="mt-10"
              cols="12"
              align="center"
              v-if="status_login === false"
            >
              <div
                class="red--text py-2"
                style="
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 450;
                  line-height: 25px;
                  letter-spacing: 0em;
                "
              >
                รหัสผ่านที่ระบุไม่ถูกต้อง กรุณาระบุรหัสผ่านที่ได้รับในอีเมล
              </div>
              <div
                class="red--text pt-0 pb-2"
                style="
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 450;
                  line-height: 25px;
                  letter-spacing: 0em;
                "
              >
                Your password is incorrect. Please enter the password received
                in the email.
              </div>
            </v-col>

            <v-col
              cols="12
              "
              align="center"
            >
              <v-row>
                <v-col
                  cols="3"
                  v-if="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                  "
                >
                </v-col>
                <v-col
                  :cols="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? 2
                      : 12
                  "
                  :align="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? 'end'
                      : 'start'
                  "
                >
                  <p
                    :class="
                      $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                        ? 'black--text mt-2'
                        : 'black--text my-0'
                    "
                    style="
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 450;
                      line-height: 25px;
                      letter-spacing: 0em;
                    "
                  >
                    Transaction ID :
                  </p>
                </v-col>
                <v-col
                  :cols="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? 3
                      : 12
                  "
                  :class="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? ''
                      : 'pt-0'
                  "
                >
                  <v-text-field
                    outlined
                    v-model="ticket_id"
                    style="border-radius: 10px"
                    :rules="empty"
                    color="green"
                    :maxlength="255"
                    dense
                    placeholder="xxxxxx (value from RP4)"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" align="center" class="pt-0">
              <v-row>
                <v-col
                  cols="3"
                  v-if="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                  "
                >
                </v-col>

                <v-col
                  :cols="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? 2
                      : 12
                  "
                  :class="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? ''
                      : 'pt-0'
                  "
                  :align="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? 'end'
                      : 'start'
                  "
                >
                  <p
                    :class="
                      $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                        ? 'black--text mt-2'
                        : 'black--text my-0'
                    "
                    style="
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 450;
                      line-height: 25px;
                      letter-spacing: 0em;
                    "
                  >
                    Password ID :
                  </p>
                </v-col>

                <v-col
                  :cols="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? 3
                      : 12
                  "
                  :class="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? ''
                      : 'pt-0'
                  "
                >
                  <v-text-field
                    outlined
                    v-model="password"
                    style="border-radius: 10px"
                    :rules="empty"
                    color="green"
                    :maxlength="255"
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="my-4" align="center">
              <v-row>
                <v-col
                  cols="5"
                  v-if="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                  "
                >
                </v-col>
                <v-col
                  :cols="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? 3
                      : 12
                  "
                >
                  <v-btn
                    color="primary"
                    block
                    class="white--text text-capitalize"
                    @click="goToHomepage()"
                    style="
                      border-radius: 10px;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 450;
                      line-height: 25px;
                      letter-spacing: 0em;
                      text-align: left;
                      height: 45px;
                    "
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import { rules } from "@/validate/validate";

export default {
  components: {},

  data() {
    return {
      valid: true,
      dialog_loading: false,
      validate_text: false,
      ticket_id: "",
      password: "",
      status_login: true,
      ticket_id: "",
      survey_id: "",
      type: "",
      type_of_survey: "Generate_QRcode",
      //rule
      empty: rules.empty,
      test_os: "",
    };
  },
  watch: {},
  computed: {},

  created() {
    this.queryParams();
  },
  mounted() {},
  methods: {
    queryParams() {
      this.ticket_id = this.$route.query.ticket_id;
    },
    goToHomepage() {
      if (this.$refs.form.validate()) {
        let body = {
          ticket_id: this.ticket_id,
          password: this.password,
        };
        this.axios.post(process.env.VUE_APP_API + "login", body).then((res) => {
          if (res.data.massage == "login success") {
            let ticket_id = res.data.ticket_info.ticket_id;
            this.$router.push("/homePage/" + ticket_id);
          } else if (res.data.massage == "ticket_id is complete") {
            this.$router.push("/loginFail");
          } else {
            this.status_login = false;
          }
        });
      } else {
        this.status_login = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
